.education_info_container{
    margin-top:6px;
}

.education_line_container{
    margin-bottom: 8px;
}

.variable{
    display: inline-block;
    width: 130px;
}

.double-digit{
    margin-left:63px;
}

@media only screen and (max-width: 680px) {
    .double-digit{
        margin-left:30px;
    }
    .education_line_container{
        margin-bottom: 10px;
    }
}