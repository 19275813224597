.project_description div{
    width:50%;
    margin-left: 109px;
    padding-bottom: 14px;
}

.project_description{
    display: flex;
    align-items: baseline;
}

.project_description img{
    align-self: center;
    margin-left: auto;
    margin-right: 25px;
}

.project_description div::before{
    left: -36px;
}

.techstack_container{
    vertical-align: middle;
}

.techstack_container svg{
    margin:0px 6px;
}

.modal{
    width: 80%;
    margin: auto;
    position: relative;
}

.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: var(--font-color)fff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  .popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }

  .last-liner{
      width:70%
  }

@media only screen and (max-width: 680px) {
    .project_description div::before{
        left:-28px;
    }
    .project_description div{
        margin-left: 64px;
    }
    .last-liner{
        width:100%
    }
    .techstack_container svg{
        height: 18px;
        margin:0px 2px;
    }
    img:nth-child(2){
        display: inline-block;
    }
}