#command_pallate_container{
    position:absolute;
    width:600px;
    max-height: 250px;
    z-index: 2000;
    background-color: var( --cp-background-color);
    top: 0px;
    left:50%;
    box-shadow: rgb(0 0 0 / 36%) 0px 0px 8px 2px;
    transform: translateX(-50%);
    
}

#command_pallate_container .theme_options{
  overflow-y:overlay;
  max-height: 214px;
  margin-top:5px;
}

#command_pallate_container .theme_options::-webkit-scrollbar {
    width: 10px;
    opacity: 0;
  }
  
  /* Track */
  #command_pallate_container .theme_options::-webkit-scrollbar-track {
    display: none;
  }
  
  /* Handle */
  #command_pallate_container .theme_options::-webkit-scrollbar-thumb {
    background:transparent;
    transition: 0.5s ease;
  }
  
  /* Handle on hover */
  #command_pallate_container .theme_options:hover::-webkit-scrollbar-thumb:hover {
    background: rgba(100, 100, 100, 0.7);
  }

  #command_pallate_container .theme_options:hover::-webkit-scrollbar-thumb {
    background: rgba(121, 121, 121, 0.4);
  }

#command_pallate_container .pallate_input{
    width:95%;
    margin: auto;
    margin-top: 6px;
}

#command_pallate_container .pallate_input input{
    width:100%;
    background-color: var(--input-background);
    color: var(--sidebar-color);
    border:none;
    padding:4px;
    font-family: SegoeWP;
    height: 25px;
}

#command_pallate_container ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:var(--sidebar-color); 
    font-family: SegoeWP;
  }

#command_pallate_container .pallate_input input:focus{
    outline-color: rgb(0,127,212);
    outline-offset: -1px;
    outline-style: solid;
}
#command_pallate_container .pallate_input input:focus-visible{
    outline-color:rgb(0,127,212);
}

#command_pallate_container .light_section{
    border-top:1px solid #3f3f46;
    border-bottom:1px solid #3f3f46;
}

#command_pallate_container .options{
    padding:0 16px;
    color:var(--cp-font-color);
    line-height: 24px;
}

#command_pallate_container .first{
  display: flex;
  justify-content: space-between;
}

#command_pallate_container .options:hover{
    background-color: var(--hover-color);
    cursor: pointer;
}


@media only screen and (max-width: 680px) {
    #command_pallate_container{
        width:263px;
        max-height: 300px;
    }
    #command_pallate_container .theme_options{
      max-height: 270px;
    }
}