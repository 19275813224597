.loader_container{
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.loader_text{
    margin-top: 10px;
    font-size:18px;
    color:#7F7F7F;
    font-family: 'Consolas-Bold';
    letter-spacing: 1px;
}