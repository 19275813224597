@import url('./fonts/font.css');
*{
  box-sizing: border-box;
}

:root {
  --primary-color: #1E1E1E;
  --secondary-color:#252526;
  --font-color:#fff;
  --html-tag:#569CD6;
  --input-background:#3c3c3c;
  --var:#569CD6;
  --var-name:#4FC1FF;
  --string-name:#CE9178;
  --inbuilt-func:#4EC9B0;
  --custom-func:#DCDCAA;
  --tag-highlight:#C586C0;
  --tag-symbol:#808080;
  --import-name:#9CDCFE;
  --num-color:#B5CEA8;
  --comment-color:#6A9955;
  --sidebar-color:#cccccc;
  --sidebar-border-color:rgba(204,204,204,0.2);
  --hover-color:#2a2d2e;
  --tab-active-color:#094771;
  --tab-content-color:rgba(212,212,212,0.4);
  --theme-color-name:#3794ff;
  --number-system-color:#858585;
  --tab-active-font-color:#fff;
  --menubar-background-color:#333333;
  --menubar-active-color:#fff;
  --menubar-font-color:rgba(255,255,255,0.4);
  --footer-background-color:#007ACC;
  --footer-font-color:#fff;
  --border-sidebar-color:transparent;
  --border-footer-color:transparent;
  --cp-background-color:#252526;
  --cp-font-color:#CCC;
  --tab-active-border-bottom-color:transparent;
  --tab-active-border-top-color:transparent;
  --mouse-cover:#007ACC;
}

body{
  margin:0px;
  padding:0px;
  background-color: var(--primary-color);
  color:var(--font-color);
  transition:0.5s ease;
}

.main_container{
  display:flex;
}

.html_tag{
  color:var(--html-tag);
  font-family: 'Consolas';
}
 
.backward{
  z-index:-2
}

.details_container{
  display:flex;
  flex-direction:column;
  flex:2;
}

.var{
  color:var(--var);
}

.var_name{
  color:var(--var-name);
}

.string{
  color:var(--string-name);
}

.inbuilt_func{
  color:var(--inbuilt-func);
}

.custom_func{
  color:var(--custom-func);
}

.tag_highlight{
  color:var(--tag-highlight);
}

.tag_symbol{
  color:var(--tag-symbol);
}

.import_name{
  color:var(--import-name);
}

.num{
  color:var(--num-color);
}

.comment{
  color:var(--comment-color);
  font-style: italic;
}


