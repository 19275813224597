@font-face {
    font-family: 'SegoeWP';
    src: local('SegoeWP'),
         url('./SegoeWP.woff') format('woff');
}

@font-face {
    font-family: 'SegoeUI';
    src: local('SegoeUI'),
         url('./Segoe UI.woff') format('woff');
}

@font-face {
    font-family: 'SegoeUIB';
    src: local('SegoeUIB'),
         url('./Segoe UI Bold.woff') format('woff');
}

@font-face {
    font-family: 'Consolas';
    src: local('Consolas'),
         url('./Consolas.woff') format('woff');
}

@font-face {
    font-family: 'Consolas-Bold';
    src: local('Consolas-Bold'),
         url('./Consolas-Bold.woff') format('woff');
}