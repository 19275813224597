#experience_container,#projects_container,#education_container,#contact_container {
    height: 100%;
    overflow:hidden;
}

#experience_container .experience_heading, #projects_container .project_heading, #education_container .education_heading, #contact_container .contact_heading{
    display:flex;
    margin: 51px 0px 30px 19px;
    align-items: center;
}

#experience_container .company_name, #projects_container .project_name, #education_container .education_name, #contact_container .contact_name{
    font-size: 26px;
    font-family: 'Consolas-Bold';
    margin-left:30px;
}

.number_system{
    font-size:18px;
    font-family: 'Consolas';
    color:var(--number-system-color);
}

.experience_info_container, .projects_info_container, .education_info_container, .contact_info_container{
    margin: 28px 0px 0px 19px;
}

.experience_line, .project_line, .education_line,.contact_line{
    margin-left:72px;
    font-size:18px;
    font-family: 'Consolas';
    color:var(--font-color);
    
}

.experience_line_container, .project_line_container, .education_line_container, .contact_line_container{
    display:flex;
    align-items: center;
    margin-bottom: 12px;
    align-items: baseline;
}

.responsibilites ul::before, .project_description div::before{
    border-left: 1px solid var(--tab-content-color);
    content: "";
    height: 100%;
    left: 84px;
    top:0px;
    position: absolute;
}

.responsibilites ul, .project_description div{
    width: 95%;
    margin:0px;
    padding:0px;
    position:relative;
    padding: 14px 0px 0px 0px;
}

.responsibilites ul li, .project_description div{
    margin-left:138px;
    font-size:18px;
    margin-bottom:12px;
    font-family: 'Consolas';
    color:var(--import-name);
}

.li_line{
    display:flex;
    align-items: flex-start;
}

@media only screen and (max-width: 680px) {
    #experience_container .company_name, #projects_container .project_name, #education_container .education_name, #contact_container .contact_name{
        font-size:20px;
        margin-left:15px;
    }
    #experience_container .experience_heading,.experience_info_container, .education_info_container, #education_container .education_heading, #contact_container .contact_heading, .contact_info_container{
        margin-left: 9px;
    }
    .experience_line, .project_line, .education_line, .contact_line{
        margin-left: 36px;
    }
    .responsibilites ul li, .project_description div{
        margin-left: 69px;
    }
    .responsibilites ul::before{
        left: 46px;
    }
    #experience_container, #projects_container, #education_container, #contact_container{
        overflow: auto;
    }
    #experience_container img, #projects_container img, #education_container img, #contact_container img{
        display: none;
    }
    .number_system,.experience_line,.responsibilites ul li,.project_line, .project_description div, .education_line,.contact_line{
        font-size:14px;
    }
}