.home_container{
    height: 100%;
    overflow:hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position:relative;
}

.home_container .heading{
    margin:10px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.gif_container{
    display:flex;
    justify-content: center;
}


.position_box{
    position:absolute;
}

#c{
    width:400px;
    height: 400px;
}

#wb{
    top:20%;
    left:5%;
}

#rh{
    top:20%;
    right:5%;
}

#cc{
    bottom:20%;
    left:5%;
}
#cc1{
    bottom:15%;
    left:5%;
}

#ps{
    bottom:20%;
    right:3%;
}

     /* Google chrome */
     @-webkit-keyframes svg-text-anim {
        40% {
           stroke-dashoffset: 0;
           fill: transparent;
         }
         60% {
           stroke-dashoffset: 0;
           fill: #569cd6;
         }
         100% {
           stroke-dashoffset: 0;
           fill: #569cd6;
         }
         
     }
     /* Most browsers */
     @keyframes svg-text-anim {
        40% {
           stroke-dashoffset: 0;
           fill: transparent;
         }
         60% {
           stroke-dashoffset: 0;
           fill: #569cd6;
         }
         100% {
           stroke-dashoffset: 0;
           fill: #569cd6;
         }
         
     }


@media only screen and (max-width: 680px) {
    #c{
        width:250px;
        height: 250px;
    }
    #rh{
        top:30%;
        right:5%;
    }
    
    #cc{
        top:40%;
        left:5%;
        bottom:auto;
    }

    #cc1{
        top:45%;
        left:5%;
        bottom:auto;
    }
    
    #ps{
        top:55%;
        right:5%;
        bottom:auto;
    }
}
