.footer_container{
    width: 100%;
    background-color: var(--footer-background-color);
    position: sticky;
    bottom: 0%;
    display: flex;
    height: 22px;
    align-items: center;
    justify-content: end;
    z-index:10;
    color:var(--footer-font-color);
    border-top-style: solid;
    border-top-color: var(--border-footer-color);
    border-top-width: 1px;
}

.link_container{
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.footer_container .icon{
    padding:0px 15px;
}

.footer_container a{
    color:var(--footer-font-color);
    text-decoration: none;
    cursor: none;
}

.footer_text{
    padding-right: 15px;
    font-family: SegoeWP;
    font-size:14px;
}
@media only screen and (max-width: 680px) {
    .footer_container .icon{
        padding:0px 10px;
    }
    .footer_container{
        justify-content: start;
    }
}

