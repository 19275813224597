.sideinfobar_container{
    background-color: var(--secondary-color);
    max-width: 15%;
    min-width:231px;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    transition: 0.5s;
    border-right-style: solid;
    border-right-color: var(--border-sidebar-color);
    border-right-width: 1px;
}

.sideinfobar_container .title{
    text-align: center;
    font-size: 15px;
    padding:15px 0px;
    font-family: 'Quando', serif;
    padding-bottom: 20px;
}

.sideinfobar_container .para{
    padding:10px 24px;
    margin: 0px;
    font-family: 'SegoeUI';
    font-size: calc(0.65em + 1vmin);
}

.sidebar_opener{
    background-color: rgba(37, 37, 38,0.5);
    padding:12px;
    height: fit-content;
    cursor: pointer;
    display: none;
    height: 36px;
}

.sideinfobar_container .quote{
    font-family: 'SegoeUIB';
    font-size: calc(0.75em + 1.2vmin);
}

.sideinfobar_container .quote .authour{
    text-align: right;
    width: 100%;
}
.sidebar_opener i{
    font-size: 16px;
}

.summary{
    align-self: baseline;
    width:100%;
    position: relative;
    border-bottom: 1px solid var(--sidebar-border-color);
}

summary::marker{
    content:"";
}

.tree-nav__item-title::-webkit-details-marker {
    display: none;
  }

  .tree-nav__item-title .icon {
    display: inline;
    padding-left: 1.6rem;
    margin-right: 0.8rem;
    color: #666;
    font-size: 1.4rem;
    position: relative;
  }
  .tree-nav__item-title .icon::before {
    top: 0;
    position: absolute;
    left: 0;
    display: inline-block;
    width: 1.6rem;
    text-align: center;
  }

.summary summary{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    padding-left:5px;
    color:var(--sidebar-color);
    letter-spacing: 1px;
    border:1px solid transparent;
    cursor: pointer;
    padding: 5px 0px;
    
}

.summary summary:focus{
    border-color: #007FD4;
}

.summary .menu{
    padding:5px 0px 0px 0px;
}

.summary .menu ul{
    padding:0px;
    margin:0px;
}

.summary .menu ul li{
    list-style-type: none;
    padding:6px 0px;
    padding-left: 30px;
    display:flex;
    align-items: center;
    cursor: none;
}

.summary .menu ul li:hover{
    background-color: var(--hover-color);
}



.summary .menu ul li.menu_active{
    background-color: var(--tab-active-color);
    color:var(--tab-active-font-color);
}

.summary .menu ul li span{
    padding-left: 6px;
    font-size: 16px;
    display: inline-block;
    font-family: 'SegoeUI';
}

summary::-webkit-details-marker {
    display: none;
}

.tree-nav__item.is-expandable::before {
    border-left: 1px solid var(--sidebar-border-color);
    content: "";
    height: 100%;
    left: 0.8rem;
    position: absolute;
    top: 2.4rem;
    height: calc(100% - 2.4rem);
    z-index:2;
}

.tree-nav__item.is-expandable[open] > .tree-nav__item-title::before {
    font-family: "ionicons";
    transform: rotate(90deg);
  }
 
  .tree-nav__item.is-expandable > .tree-nav__item-title::before {
    will-change: transform;
    transition: transform 300ms ease;
    font-family: "ionicons";
    color: var(--font-color);
    font-size: 14px;
    content: "\f125";
    left: 0;
    display: inline-block;
    width: 1.5rem;
    text-align: center;
  }

  details[open] > div {
    animation-name: slideDown;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      height: 0;
      padding: 0;
    }
  
    to {
      opacity: 1;
      height: var(--contentHeight);
    }
  }

.code_icon{
    width:10%;
}
  
@media only screen and (max-width: 680px) {
    .sideinfobar_container{
        position: absolute;
    }
    .sidebar_opener{
        display: block;
        position:absolute;
        transition: 0.5s;
        top:0%;

        left:48px;
    }
}