.contact_info_container{
    margin-top:6px;
}

.contact_line_container{
    margin-bottom: 4px;
}

.tab{
    margin-left: 121px;
}

.partition{
    margin-top: 15px;
}

#contact_container ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:var(--comment-color); 
    opacity: 1; /* Firefox */
    font-family: 'Consolas'
  }

  #contact_container input,textarea,#contact_container button{
    background: transparent;
    outline: none;
    border: none;
    width: 92px;
    font-size: 18px;
    font-family: 'Consolas'
  }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background: transparent !important;
    outline: none;
    border: none;
    width: 92px;
    font-size: 18px;
    font-family: 'Consolas';
    -webkit-box-shadow: 0 0 0px 1000px transparent;
    -webkit-text-fill-color:var(--comment-color);
    transition: background-color 5000s ease-in-out 0s
}

.tab-double-digit{
    margin-left: 112px;
}

.tab2{
    margin-left:152px;
}

@media only screen and (max-width: 680px) {
   .tab{
       margin-left: 87px;
   }
   .tab-double-digit{
    margin-left: 85px;
}

    .tab2{
        margin-left:100px;
    }
    input,textarea,button{
        font-size: 14px !important;
        width: 73px;
    }
}