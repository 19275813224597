#skillinfo_container {
    height: 100%;
    overflow:hidden;
    color:#fff;
}

.skill_info_heading{
    margin:30px 0px 30px 30px;
}

.skill_container{
    width:90%;
    margin:20px 0px 30px 35px;
}

.skill_container_info{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.skill_left{
    display:flex;
    align-items: center;
}

.skill_name{
    font-family: 'Consolas-Bold';
    font-size:20px;
    margin-left: 7px;
}

.skill_right{
    font-family: 'Consolas-Bold';
    font-size:18px;
}

.skill_bar_container{
    margin-top: 10px;
    height: 12px;
    width: 100%;
    background: #C4C4C4;
    border-radius: 12px;
}

.skill_bar{
    height: 12px;
    width: 70%;
    background: #F98600;
    border-radius: 12px;
    box-shadow: 0px 0px 2px 3px rgba(249,134,0,0.5);
}

@media only screen and (max-width: 680px) {
    .skill_info_heading{
        margin:30px 0px 30px 15px;
    }
    .skill_container{
        margin:20px 0px 30px 20px;
    }
}