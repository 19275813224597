.sider_container{
    height: 100%;
    background-color: var(--menubar-background-color);
    max-width:5%;
    min-width: 65px;
    width: -webkit-fill-available;
    box-shadow:0px 8px 2px var(--sidebar-border-color);
    z-index:5;
}

nav > ul{
    margin:0px;
    padding:0px;
}

nav > ul li{
    list-style-type: none;
    text-align: center;
    padding: calc(0.75em + 1vmin) 0px;
    cursor: none;
}

.sider_container li:hover .icon{
    color:#007FD4;
}

.logo{
    font-family: 'Sofia', cursive;
    font-size: 31px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.sider_container .icon{
    font-size: calc(0.75em + 2vmin);
    color:var(--menubar-font-color);
    transition: 0.5s ease;
}

.active{
    border-left: 3px solid var(--menubar-active-color);
}

.sider_container .active .icon{
    color:var(--menubar-active-color);
}

@media only screen and (max-width: 680px) {
    .sider_container{
        width: 48px;
        min-width: unset;
        max-width: unset;
    }
    .sider_container .icon{
        font-size: 1.3rem;
    }
    .logo{
        font-size: 28px;
    }
}
