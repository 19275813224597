.menu_container{
  height: 36px;
  display:flex;
  align-items: center;
  background-color: var(--secondary-color);
}

.menu_container .menu{
    padding:0px 20px;
    font-size:18px;
    display:flex;
    align-items: center;
    height: 100%;
    font-family: 'SegoeUI';
}

.menu_container .active_tab{
    background-color: var(--primary-color);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--tab-active-border-bottom-color);
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--tab-active-border-top-color);
}

.menu_container .menu .icon_container{
    padding-right: 5px;
    display: flex;
    align-items: center;
}

.menu_container .menu .icon{
    font-size: 10px;
    color:#e37933;
}

.close_icon{
    font-size:14px;
    padding-left: 15px;
    cursor: pointer !important;
}

@media only screen and (max-width: 680px) {
    .menu_container .menu{
        padding:0 15px;
        font-size:15px;
    }
}
